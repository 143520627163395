<template>
  <div class="table-responsive">
    <div v-show="loading" class="card__loader"></div>
    <div class="row mx-0 my-2">
      <div class="form-group col-12 col-md-6 ml-n2">
        <label class="mr-sm-2">Find Transactions</label>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          v-model="search"
        />
      </div>
      <div v-if="!length" class="col-12 col-md-6 text-right">
        <button class="btn btn-primary mx-2" @click="downloadData">Export</button>
        <button class="btn btn-primary" @click="print">Print</button>
      </div>
    </div>
    <table class="table table-striped mb-0 table-responsive-sm" id="table">
      <template v-if="!length">
        <paginate
          v-if="tableData.length >= 0"
          ref="paginate"
          name="items"
          :list="tableData"
          :per="per"
          tag="tbody"
        >
          <tr>
            <th></th>
            <th>Transaction Type</th>
            <th>Date</th>
            <th>Currency</th>
            <th>Account</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
          <tr v-for="data in paginated('items')" :key="data.shortcode" @click="openHistory(data)">
            <td v-if="data.type === 'Withdrawal'">
              <span class="buy-thumb"><i class="mdi mdi-arrow-up"></i></span>
            </td>
            <td v-else>
              <span class="sold-thumb"><i class="mdi mdi-arrow-down"></i></span>
            </td>
            <td v-if="data.type === 'Withdrawal'">
              <span class="badge badge-success p-2">Trade</span>
            </td>
            <td v-else>
              <span class="badge badge-danger p-2">Deposit</span>
            </td>
            <td>{{ data.date }}</td>
            <td v-if="data.type === 'Withdrawal'">
              <cryptoicon symbol="btc" size="24" /> {{ data.currency }}
            </td>
            <td v-else>
              <cryptoicon symbol="usd" size="24" /> {{ data.currency }}
            </td>
            <td>{{ data.account }}</td>
            <td v-if="data.type === 'Withdrawal'" class="text-success">
              {{ data.amount }} BTC
            </td>
            <td v-else class="text-danger">{{ data.amount }} USD</td>
            <td>{{ data.status }}</td>
          </tr>
        </paginate>
      </template>
      <template v-else>
        <tbody>
          <tr>
            <th></th>
            <th>Transaction Type</th>
            <th>Date</th>
            <th>Currency</th>
            <th>Account</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
          <tr v-for="(data, index) in tableData" :key="index">
            <td v-if="data.type === 'Withdrawal'">
              <span class="buy-thumb"><i class="mdi mdi-arrow-up"></i></span>
            </td>
            <td v-else>
              <span class="sold-thumb"><i class="mdi mdi-arrow-down"></i></span>
            </td>
            <td v-if="data.type === 'Withdrawal'">
              <span class="badge badge-success p-2">Trade</span>
            </td>
            <td v-else>
              <span class="badge badge-danger p-2">Deposit</span>
            </td>
            <td>{{ data.date }}</td>
            <td v-if="data.type === 'Withdrawal'">
              <cryptoicon symbol="btc" size="24" /> {{ data.currency }}
            </td>
            <td v-else>
              <cryptoicon symbol="usd" size="24" /> {{ data.currency }}
            </td>
            <td>{{ data.account }}</td>
            <td v-if="data.type === 'Withdrawal'" class="text-success">
              {{ data.amount }} BTC
            </td>
            <td v-else class="text-danger">{{ data.amount }} USD</td>
            <td>{{ data.status }}</td>
          </tr>
        </tbody>
      </template>
    </table>
    <paginate-links v-if="!length" for="items" :async="true"></paginate-links>
    <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
  </div>
</template>

<script>
import { json2excel } from 'js2excel';
import api from "@/api";

export default {
  props: {
    length: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    deposits: [],
    trades: [],
    accounts: [],
    wallets: [],
    paginate: ["items"],
    search: "",
    loading: false,
    per: 10,
  }),
  computed: {
    tableData() {
      if (
        this.accounts.length ||
        this.wallets.length ||
        this.deposits.length ||
        this.trades.length
      ) {
        const deposits = this.deposits.map((deposit) => {
          const date = new Date(deposit.created_at);
          const currency = "USD";
          let account = this.accounts.find(
            (el) => el.id === deposit.account_id
          )
          if (account) {
            account = account.account_number;
          }
          const amount = deposit.amount_fiat;
          const status = deposit.verified_at ? "Completed" : "Pending";
          const id = deposit.id;
          const shortcode = deposit.short_code;
          return {
            type: "Deposit",
            date: date.toLocaleDateString(),
            rawDate: date,
            currency,
            account: `Using - Bank ${account}`,
            amount,
            status,
            id,
            shortcode
          };
        });
        const trades = this.trades.map((trade) => {
          const date = new Date(trade.created_at);
          const currency = "BTC";
          let account = this.wallets.find((el) => el.id === trade.wallet_id);
          if (account) {
            account = account.description;
          }
          const amount = parseFloat(trade.amount_crypto).toFixed(5);
          const status = trade.blockchain_id ? "Completed" : "Pending";
          const id = trade.id;
          const shortcode = trade.short_code;
          return {
            type: "Withdrawal",
            date: date.toLocaleDateString(),
            rawDate: date,
            currency,
            account: `Using - Wallet ${account}`,
            amount,
            status,
            id,
            shortcode
          };
        });
        const data = [...deposits, ...trades];
        let sorted = [...data]
          .sort((a, b) => new Date(a.rawDate) - new Date(b.rawDate))
          .reverse();
        if (this.length) {
          sorted = sorted.slice(0, 5);
        }

        if (this.search) {
          sorted = sorted.filter((el) => {
            return (
              el.type.toLowerCase().includes(this.search) ||
              el.amount.toString().includes(this.search) ||
              el.account.toLowerCase().includes(this.search) ||
              el.currency.toLowerCase().includes(this.search)
            );
          });
        }

        return sorted;                          
      }else{
        return [];
      }
    },
    expiryDate() {
      if (this.deposits.length > 0) {
        const lastDeposit = [...this.deposits].sort((a, b) => b.id - a.id)[0];
        const date = new Date(lastDeposit.created_at);
        date.setHours(48);
        return date.toDateString();
      }
      return new Date().toDateString();
    },
  },
  watch: {
    tableData(val) {
      if (val.length > 0) {
        setTimeout(() => {
          if (this.$refs.paginate) {
            this.$refs.paginate.goToPage(1);
          }
        }, 100);
      }
    }
  },
  mounted() {
    this.getDeposits();
    this.getTrades();
    this.getAccounts();
    this.getWallets();
  },
  methods: {
    getDeposits() {
      this.loading = true;
      api.deposit.read().then((res) => {
        this.loading = false;
        this.deposits = res || [];
      });
    },
    getTrades() {
      this.loading = true;
      api.trade.read().then((res) => {
        this.loading = false;
        this.trades = res || [];
      });
    },
    getAccounts() {
      api.account.read().then((res) => {
        this.accounts = res || [];
      });
    },
    getWallets() {
      api.wallet.read().then((res) => {
        this.wallets = res || [];
      });
    },
    downloadData() {
        const data = this.tableData.map((el) => {
            return {
                id: el.id,
                date: el.rawDate,
                currency: el.currency,
                amount: el.amount,
                account: el.account,
                type: el.type,
                status: el.status,
            };
        })
      try {
        json2excel({
            data,
            name: 'Getcoins transactions',
            formateDate: 'yyy/mm/dd',
        });
      } catch (error) {
        this.$toast.error('Failed to save file');
      }
    },
    print() {
      this.per = this.tableData.length + 1;
      setTimeout(() => {
        window.frames["print_frame"].document.body.innerHTML = document.querySelector(".table-responsive").innerHTML;
        window.frames["print_frame"].window.print();
        window.frames["print_frame"].window.focus();
        this.per = 10;
      }, 0);
    },
    openHistory(data){
      if(data.type != 'Withdrawal'){
        this.$router.push({ path: `/transaction/deposit/${data.id}` });
      } else {
        this.$router.push({ path: `/transaction/trade/${data.id}` });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.paginate-links::v-deep {
  display: flex;
  justify-content: center;
  li.active {
    a {
      background-color: var(--primary);
      color: #fff;
    }
  }
  a {
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    border-radius: 4px;
    margin: 10px;
    border: 1px solid var(--primary);
    font-size: 1.2rem;
    font-weight: bold;
  }
}
.card-profile__info h5 {
  display: inline-block;
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-color);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;

  h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: var(--body-color);

    span {
      // background: var(--primary);
      color: var(--primary);
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid var(--border-color);
      height: 40px;
      width: 40px;
      display: inline-block;
    }
  }

  .verified,
  .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;

    span {
      background: var(--success);
      color: var(--white);
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .not-verify {
    span {
      background: var(--danger);
    }
  }
}

// Security
.id_card {
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 20px 0px;
}

table {
  color: var(--text);
}

.table {
  @include screen("phone-land") {
    min-width: unset;
    max-width: 100%;
    overflow-x: hidden;
    th {
      display: none;
    }

    tbody {
      display: block;
    }

    tr {
      border: 1px solid;
      margin: 20px 0;
      display: block;
    }

    td {
      display: block;
      text-align: left !important;
      padding-top: 5px;
      padding-bottom: 5px;

      &:first-child {
        display: none;
      }
    }

    td::before {
      color: var(--headings-color);
      font-weight: 500;
      margin: 10px 0;
      font-size: 1rem;
      display: block;
    }

    td:nth-of-type(2):before {
      content: "Transaction Type";
    }
    td:nth-of-type(3):before {
      content: "Date";
    }
    td:nth-of-type(4):before {
      content: "Currency";
    }
    td:nth-of-type(5):before {
      content: "Account";
    }
    td:nth-of-type(6):before {
      content: "Amount";
    }
    td:nth-of-type(7):before {
      content: "Status";
    }
  }
}

.table-responsive {
  @media print {
    button {
      display: none;
    }
  }
}
</style>